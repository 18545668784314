<template>
  <div class="pro-content">
    <div class="menu-box">
      <div class="menu-item ellipsis2" v-for="(item,index) in list" :key="index" :class="{'active':id === item.value}" @click="onMenuChange(item.value)">{{item.text}}
      </div>
    </div>
    <div class="van-list" ref="scrollView" @scroll="onScroll">
      <div class="ivv-series-box" v-for="(item,idx) in list" :key="idx" :data-id="item.value">
        <div class="subtitle"><span>{{item.text}}</span></div>
        <van-cell v-for="(it,i) in item.productList" :key="i" :border="false" @click="pageJump(it)">
          <img class="media-object" :src="it.goodsImg" />
          <div class="media-body">
            <div class="pro-name ellipsis1">{{ it.goodsName }}</div>
            <div class="prod-txt ellipsis1">{{ it.goodsContent }}</div>
            <div class="prod-txt ellipsis1">{{ it.goodsEffect }}</div>
            <div class="ivv-price"><span>￥</span>{{ it.goodsPrice }}</div>
          </div>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductScrollList",
  props: {
    list: {
      type: Array,
      default() {
        return []
      },
    },
    brandId: {
      type: Number,
      default: 3
    },
  },
  data() {
    return {
      pg: 0,
      ps: 10,
      id: 0,
      loading: false,
      finished: false,
      productList: [],
      isScroll: false,
      scrollView: null
    }
  },
  created() {
    if (this.list.length > 0) {
      this.id = this.list[0].value;
    }
    this.loading = false;
    this.finished = true;
  },
  mounted() {
    this.scrollView = this.$refs.scrollView;
  },
  methods: {
    //切换菜单
    onMenuChange(id) {
      if (this.id === id) return;
      this.id = id;
      if (!this.scrollView) return;
      let list = this.scrollView.children || [];
      let len = 0;
      for (let i = 0; i < list.length; i++) {
        let val = +list[i].getAttribute("data-id");
        if (id === val) {
          break;
        }
        len += list[i].clientHeight;
      }
      this.isScroll = true;
      this.scrollView.scrollTo(0, len)
      setTimeout(() => {
        this.isScroll = false
      }, 50);
    },
    onScroll(e) {
      if (this.isScroll) return;
      this.isScroll = true;
      let list = e.target.children || [];
      let scrollTop = e.target.scrollTop;
      let len = 0;
      for (let i = 0; i < list.length; i++) {
        len += list[i].clientHeight;
        if (len > scrollTop) {
          let id = +list[i].getAttribute("data-id");
          this.id = id;
          break;
        }

      }
      setTimeout(() => {
        this.isScroll = false
      }, 100);
    },
    //获取商品列表
    getProductList() {
      this.pg += 1;
      this.get("/GroupBuy/Product/GetProductMoreList", {
        brandTypeId: this.brandId,
        pageIndex: this.pg,
        pageSize: this.ps,
        valueId: this.id
      }, 2).then(json => {
        this.loading = false;
        if (json.code === 1) {
          let oldList = this.productList;
          let list = json.response || [];
          this.productList = oldList.concat(list);
          if (list.length < this.ps) this.finished = true;
        }
      });
    },
    //页面跳转
    pageJump(item) {
      let path = this.baseHost + `/7999/MiDian/html/seller/groupon_detail.html?proId=${item.goodsId}&brandId=${this.brandId}`
      window.location.href = path;
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.pro-content {
  height: 100%;
  display: flex;
  border-top: 1px solid #f1f1f1;
  .menu-box {
    width: 70px;
    height: 100%;
    overflow-y: auto;
    background-color: #f2f2f2;

    .menu-item {
      color: #666;
      height: 50px;
      display: flex;
      padding: 0 5px;
      font-size: 12px;
      text-align: center;
      align-items: center;
      justify-content: center;

      &.active {
        background-color: #fff;
      }
    }
  }

  .van-list {
    height: 100%;
    width: calc(100% - 70px);
    padding: 11px 15px;
    overflow-y: auto;
    box-sizing: border-box;

    .subtitle {
      text-align: center;
      font-size: 16px;
      line-height: 34px;
      font-weight: bold;
      position: relative;

      span {
        position: relative;
        z-index: 10;
      }

      &:after {
        content: '';
        position: absolute;
        left: calc(50% - 50px);
        width: 100px;
        bottom: 10px;
        height: 4px;
        border-radius: 4px;
        background-color: #ffdfe2;
      }
    }

    .van-cell {
      width: calc(50% - 5px);
      margin-right: 10px;
      margin-bottom: 12px;
      border-radius: 6px;
      display: inline-block;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.06);

      .media-object {
        width: 100%;
      }

      .media-body {
        font-size: 12px;

        .pro-name {
          color: #666;
        }

        .prod-txt {
          color: #999;
        }

        .ivv-price {
          color: #ff99a1;
          font-weight: bold;

          span {
            font-size: 10px;
            font-weight: normal;
          }
        }
      }

      &:nth-child(2n - 1) {
        margin-right: 0;
      }
    }

    .van-list__loading {
      width: 100%;
    }
  }
}
</style>