<template>
  <div class="container">
    <van-tabs v-model="brandId" class="brand-box" @change="onBrandChange">
      <van-tab class="" :title="item.text" :name="item.brandId" v-for="(item, index) in brandList" :key="index">
        <van-swipe class="my-swipe" :ref="'mySwipe_' + item.brandId" :autoplay="isFixed ? 0 : 5000"
          indicator-color="white" :loop="item.brandId === brandId">
          <van-swipe-item v-for="(it, idx) in getBannerById" :key="idx">
            <img :src="it.imgUrl" @load="onImageLoad" />
          </van-swipe-item>
        </van-swipe>
        <van-tabs :class="isFixed ? 'fixed-tabs' : ''" v-model="categoryId" title-inactive-color="#999"
          title-active-color="#333" color="#FF98A0" @change="onCategoryChange" v-if="getCategoryByBid.length > 0">
          <van-tab :title="it.text" :name="it.value" v-for="(it, idx) in getCategoryByBid" :key="idx">
            <ProductScrollList :list="leftMenuList" :brand-id="brandId" v-if="leftMenuList.length > 0" />
          </van-tab>
          <!-- <van-tab :title="scrollY + '_' + swiperImgHeight" /> -->
        </van-tabs>
        <ProductScrollList :list="leftMenuList" :brand-id="brandId" v-else-if="leftMenuList.length > 0" />
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import ProductScrollList from "../../components/Brand/ProductScrollList"

export default {
  name: "ProductList",
  data() {
    return {
      brandId: 3,
      isFixed: false,
      resizeHeight: false,
      categoryId: 0,//系列id
      brandList: [{ brandId: 3, text: '美妆' }, { brandId: 4, text: '大健康' }],
      categoryList: [], //大类列表
      leftMenuList: [],//左侧菜单列表
      bannerList: [],//banner列表
      swiperImgHeight: 177, //轮播图的高度
    }
  },
  components: { ProductScrollList },
  created() {
    let { brandId } = this.$route.query;
    if (brandId > 0) {
      this.brandId = +brandId
    }
    this.getBannerList();
    this.getTypeByBid();
  },
  mounted() {
    let timer = null
    window.addEventListener("scroll", () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        this.onPageScroll(window.scrollY)
      }, 50);
    })
  },
  methods: {
    onPageScroll(scrollY) {
      console.log(scrollY, this.swiperImgHeight);
      if (scrollY > this.swiperImgHeight) {
        if (!this.isFixed) this.isFixed = true;
      }
      else this.isFixed = false;
    },
    //切换品牌
    onBrandChange(brandId) {
      this.brandId = brandId;
      this.leftMenuList = [];
      this.getBannerList();
      this.getTypeByBid();
      window.scrollTo(0, 0)
    },
    //切换系列大类
    onCategoryChange(id) {
      this.categoryId = id;
      this.leftMenuList = [];
      this.getSeriesByBid(id);
    },
    //获取banner图
    getBannerList() {
      if (this.bannerList.find(it => {
        return it.brandId === this.brandId
      })) return;
      this.get("/GroupBuy/Product/GetProductMoreBannerListV2?", { brandTypeId: this.brandId }, 2).then(json => {
        if (json.code === 1) {
          let list = { brandId: this.brandId, list: json.response || [] }
          this.bannerList = this.bannerList.concat(list);
        }
      });
    },
    getTypeByBid() {
      if (this.brandId === 4 || this.categoryList.find(it => {
        return it.brandId === this.brandId
      })) return this.initSeriesFun();
      this.$toast.loading({ message: '加载中...', duration: 0, overlay: true });
      this.get("/GroupBuy/Product/GetProductTypeListV2", {
        brandId: this.brandId
      }, 2).then(json => {
        if (json.code === 1) {
          let list = json.response;
          this.categoryList.push({ brandId: this.brandId, list: list });
          this.initSeriesFun();
        } else {
          this.$toast.clear();
        }
      })
    },
    initSeriesFun() {
      let data = this.categoryList.find(it => {
        return it.brandId === this.brandId;
      })
      if (data) {
        let id = data.list[0].value;
        this.categoryId = id;
        this.getSeriesByBid(id)
      } else {
        this.getSeriesByBid(0)
      }

    },
    //获取菜单系列
    getSeriesByBid(typeId) {
      this.get("/GroupBuy/Product/GetProductAndTypeListV2", {
        parentValue: typeId,
        brandTypeId: this.brandId
      }, 2).then(json => {
        this.$toast.clear();
        if (json.code === 1) {
          this.leftMenuList = json.response;
        }
      })
    },
    //图片加载完
    onImageLoad(e) {
      if (e.path && e.path.length > 0) {
        this.swiperImgHeight = e.path[0].height;
      }
    }
  },
  computed: {
    getCategoryByBid() {
      let data = this.categoryList.find(it => {
        return it.brandId === this.brandId;
      });
      return data ? data.list : [];
    },
    //获取顶部高度
    getTopHeight() {
      // let height = this.getCategoryByBid.length > 0 ? 44 : 0;
      let height = 0;
      let el = this.$refs['mySwipe_' + this.brandId];
      if (el && el.length > 0) {
        height += el[0].$el.clientHeight;
      }
      if (this.resizeHeight) {//加这段只是为了刷新计算
        this.resizeHeight = false;
        height *= 1;
      }
      return height;
    },
    //根据品牌id获取banner
    getBannerById() {
      let data = this.bannerList.find(it => {
        return it.brandId === this.brandId;
      });
      return data ? data.list : [];
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 44px;

  /deep/.brand-box {
    height: 100%;

    >.van-tabs__wrap {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;

      .van-tab {
        .van-tab__text {
          color: #999;
          width: 89px;
          text-align: center;
          border-radius: 22px;
          display: inline-block;
          border: 1px solid #999;
        }
      }

      .van-tab--active {
        .van-tab__text {
          color: #fff;
          border-color: #ff98a0;
          background-color: #ff98a0;
        }
      }

      .van-tabs__line {
        display: none;
      }
    }

    >.van-tabs__content {
      height: calc(100% - 44px);

      .van-tab__pane {
        height: 100%;
      }
    }

    .my-swipe {
      .van-swipe-item {
        padding: 0 15px;
        box-sizing: border-box;
      }

      img {
        width: 100%;
        border-radius: 6px;
      }
    }
  }

  .fixed-tabs {
    height: 100%;
    padding-top: 44px;
    background-color: #fff;

    /deep/.van-tabs__wrap {
      position: fixed;
      top: 44px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }

    /deep/.van-tabs__content {
      height: 100%;

      .van-tab__pane {
        height: 100%;
      }
    }
  }
}</style>